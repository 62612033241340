import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  FormControl,
  Select,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import KebabOptions from "../../dashboard/src/common/KebabOptions.web";
import { verticalDots, empty } from './assets'
import SimplePagination from "../../dashboard/src/common/SimplePagination.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const MarketCard = styled(Link)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "18px 16px",
  flexShrink: 0,
  borderRadius: "12px",
  width: "100%",
  minHeight: "199px",
  marginBottom: "0",
  backgroundColor: "#ffffff",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ECFBF1"
  }
});

const MarketCardBody = styled("div")({
  minHeight: "122px",
  marginBottom: "0",
  position: "relative",
  width: "100%"
});

const Tags = styled(Typography)({
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 700,
  fontStyle: "normal",
  marginBottom: "16px",
  lineHeight: "160%"
});

const Desc = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  marginBottom: "24px",
  color: "#34373A",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "160%",
  overflow: "hidden",
  display: '-webkit-box',
  marginTop: '10px',
  "-webkit-line-clamp": 2,
  "-webkit-box-orient": "vertical",
});

const ContactButton = styled("div")({
  display: "flex",
  gap: "5px"
});

const ContactTagPill = styled('button')({
  fontSize: "12px",
  padding: "10px 51px 11px 51px",
  color: "#34373A",
  fontStyle: "normal",
  fontFamily: "Inter",
  fontWeight: 700,
  lineHeight: "160%",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  textDecoration: "none",
  maxWidth: '150px',
  width: '100%',
  border: 'none',
  cursor: 'pointer',
});

const MenuIcon = styled(Box)({
  position: "absolute",
  cursor: "pointer",
  right: "4px",
  zIndex: 1
});

const CardGrid = styled(Grid)({
  width: " calc(100% + 14px) !important",
  marginLeft: "-12px", // "-22px",
  paddingTop: "20px !important",
  paddingBottom: "0 !important",
  paddingLeft: '32px',
  minHeight: '600px',
  '@media (max-width:600px)':{
    paddingLeft: '0px !important',
    marginLeft: "9px !important",
    maxWidth:'95% !important'
  }
});

const FilterDropdown = styled(Select)({
  height: "48px",
  paddingBottom: "5px!important;",
  "& *": {
    background: "#fff !important;",
    fontWeight: 700,
    fontFamily: "Lato",
    fontSize: "15px !important",
    lineHeight: "19.2px",
    color: "#34373A",
    fontStyle: "normal",
  },
  '&:focus': {
    borderColor: '#cccccc',
  },
});

const FilterContainer = styled("div")({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '-72px',
  paddingRight: '40px',
});

const options = ["Remove from list", "Archive", "Assign to team member"];

// Customizable Area End

import VendoruseraccountmarketplacehyperlinksController, {
  Props,
  configJSON,
} from "./VendoruseraccountmarketplacehyperlinksController";

export default class Vendoruseraccountmarketplacehyperlinks extends VendoruseraccountmarketplacehyperlinksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getmarketplaceData()
    this.gettagsList()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.state.marketPlaceData ?
            <>
              <FilterContainer>
                <FormControl>
                  <FilterDropdown
                    data-test-id="catagory"
                    value={this.state.filterValue}
                    onChange={this.handleChangeSort}
                    disableUnderline
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: '180px',
                          maxHeight: 200,
                          top: "195px",
                          left: "1365px"
                        },
                      },
                      anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom',
                      },
                      transformOrigin: {
                        horizontal: 'left',
                        vertical: 'top',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {
                      this.state.tagList?.map((item: any) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    }
                  </FilterDropdown>
                </FormControl>
              </FilterContainer>
              <CardGrid spacing={0} container>
                {
                  this.state.marketPlaceData.map((item: any) => (
                    <Grid item xs={12} sm={6} md={4} style={{ margin: '8px 0' }} key={item.id}>
                      <Box style={{ position: 'relative' }}>
                        <MarketCard to={`/transform/hub/marketplace/details/${item.id}`}>
                          <MarketCardBody
                            data-test-id={`card_${item.id}`}
                            onMouseEnter={() => this.handleCardHover(item.id)}
                            onMouseLeave={this.handleCardLeave}
                          >
                            <Box display="flex" width="100%"  >
                              <Tags style={{ color: configJSON.Colors[item.category] || "#F96C30" }}>
                                {item.category}
                              </Tags>
                            </Box>
                            <Box style={{ minHeight: '48px', display: 'flex', alignItems: 'center' }}>
                              <img src={item.image} style={{ maxWidth: '120px', maxHeight: '48px' }} />
                            </Box>
                            <Desc>{item.title}</Desc>
                          </MarketCardBody>
                          <ContactButton>
                            <ContactTagPill
                              data-test-id={`contact_${item.id}`}
                              onMouseEnter={() => this.handleCardHover(item.id)}
                              onMouseLeave={this.handleCardLeave}
                              style={{ backgroundColor: this.state.isCardHovered === item.id ? '#43D270' : '#B1B3C3' }}
                              disabled={!item.contact}
                              onClick={(e) => this.handleButtonClick(e, item.contact)}>Contact</ContactTagPill>
                          </ContactButton>
                        </MarketCard>
                      </Box>
                    </Grid>
                  ))
                }
              </CardGrid>
              <Box style={webStyle.paginator}>
                <SimplePagination currentPage={this.state.pageNo} count={this.state.totalData} onChange={this.handleOnPaginationChange} />
              </Box>
            </> :
            <Box style={webStyle.errorContaoner as any}>
              <img src={empty} alt="empty" height={182} width={197} />
              <Typography style={webStyle.errorText as any} >Please refresh the page to see the contents. <br />If problem persists, try logging out and log back in.</Typography>
            </Box>
        }
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  paginator: {
    marginTop: '40px'
  },
  errorContaoner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '160px'
  },
  errorText: {
    fontWeight: 400,
    fontFamily: "Lato",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#34373A",
    fontStyle: "normal",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    marginTop: '47px',
    maxWidth: "818px"
  }
};
// Customizable Area End
