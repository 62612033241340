import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { download } from "./assets";
import {
    Typography,
    IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Box, Modal, Paper, Button
} from "@material-ui/core";
import TablePagination from "../../dashboard/src/common/TablePagination.web";
import InvoicePDF from "../../../components/src/Invoicepdf.web";

const tableLabels = {
    date: "Date",
    plan: "Plan",
    quantity: "Quantity",
    price: "Total Price",
    download: "Download"
};

const InvoiceTableContainer = styled(TableContainer)({
    minHeight: "583px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: '26px',
    padding: '12px',
    paddingLeft: "0px"
});

const InvoiceTable = styled(Table)({
    width: "100%",
    borderCollapse: "separate",
});

const InvoiceTableBody = styled(TableBody)({
    color: "inherit",
});

const InvoiceSpacing = styled("div")({
    height: 16,
    display: "table-header-group",
});

const InvoiceTableCellHead = styled(TableCell)({
    minHeight: "48px",
    padding: "8px",
    flexShrink: 0,
    borderBottom: "none",
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const InvoiceTableCell = styled(TableCell)({
    borderBottom: "1px solid #F1F5F9",
    padding: "8px",
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.2px",
});

const InvoiceTableHead = styled(TableHead)({
    backgroundColor: "#F5F5F5",
    borderRadius: "10px",
});

const InvoiceTableRowHead = styled(TableRow)({
    borderRadius: "20px !important",
    overflow: "hidden",
    "& :first-child": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
    },
    "& :last-child": {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    },
});

const SubscribeButton = styled(Button)({
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    backgroundColor: '#43D270',
    height: "40px",
    padding: "0px 16px",
    alignItems: "center",
    color: '#34373A',
    borderRadius: "8px",
    cursor: 'pointer',
    border: 'none',
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%"
});

const Container = styled(Box)({
    padding: "0 67px 20px 42px",
    '@media(max-width: 768px)': {
        padding: "0 10px 20px 10px"
    }
});
const StyledBox = styled(Box)({
   '@media(max-width: 600px)': {
      width:'580px',
      paddingTop:'5px'
    }
});

// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
} from "./CustomisableusersubscriptionsController";

export default class Invoices extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getInvoices();
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Container>
                <Modal open={this.state.isShowInvoice} onClose={this.handleCloseInvoice} style={webStyle.modalStyle}>
                    <Paper style={webStyle.videoModel as React.CSSProperties} className='invoicePdf'>
                        <StyledBox
                        >
                        <Box style={webStyle.header}>
                            <Typography style={webStyle.title}>Invoice</Typography>
                            <SubscribeButton data-test-id="downloaddata" onClick={this.handleGeneratePdf}>Download</SubscribeButton>
                        </Box>
                        </StyledBox>
                        <InvoicePDF invoice={this.state.invoiceData} contactInfo={this.state.contactInfo}/>
                    </Paper>
                </Modal>
                <Typography style={webStyle.title}>Invoices</Typography>
                <InvoiceTableContainer>
                    <InvoiceTable aria-label="users table">
                        <InvoiceTableHead>
                            <InvoiceTableRowHead>
                                <InvoiceTableCellHead>
                                    {tableLabels.date}
                                </InvoiceTableCellHead>
                                <InvoiceTableCellHead>{tableLabels.plan}</InvoiceTableCellHead>
                                <InvoiceTableCellHead>{tableLabels.quantity}</InvoiceTableCellHead>
                                <InvoiceTableCellHead>{tableLabels.price}</InvoiceTableCellHead>
                                <InvoiceTableCellHead>{tableLabels.download}</InvoiceTableCellHead>
                            </InvoiceTableRowHead>
                        </InvoiceTableHead>
                        <InvoiceSpacing />
                        <InvoiceTableBody>
                            {this.state.invoices && this.state.invoices.map((invoice) => {
                                return (
                                    <TableRow>
                                        <InvoiceTableCell style={{ fontWeight: 700, width: '280px', minWidth: 100 }}>
                                            {invoice.invoice_date}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell style={{ width: '280px' }}>
                                            {invoice.plan_name}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell style={{ width: '280px' }}>
                                            {invoice.quantity}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell style={{ width: '140px' }}>
                                            {invoice.currency_symbol}{invoice.amount.toFixed(2)}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell style={{ width: '96px' }}>
                                            <IconButton data-test-id={`download${invoice.id}`} onClick={() => this.handleShowInvoice(invoice)}>
                                                <img src={download} alt="download" width={24} height={24} />
                                            </IconButton>
                                        </InvoiceTableCell>
                                    </TableRow>
                                );
                            })}
                        </InvoiceTableBody>
                    </InvoiceTable>
                    <TablePagination 
                        currentPage={this.state.page}
                        count={this.state.totalUser}
                        onChange={this.handleTablePageChange}
                        handleChangeNumber={this.handleTableNumberChange}
                        perPage={this.state.perPage} />
                </InvoiceTableContainer>
            </Container>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    title: {
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "125%",
        margin: '32px 0 23px 0'
    },
    button: {
        minWidth: '140px',
        maxWidth: '140px',
        minHeight: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "#34373A",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "150%",
        borderRadius: "8px",
        textDecoration: "none",
        border: "1px solid #000"
    },
    paginator: {
        margin: '0 80px 0 35px'
    },
    modalStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    videoModel: {
        width: '620px',
        maxWidth: "100%",
        padding: '10px',
        borderRadius: '8px',
        height: '700px',
        overflow: 'auto'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px 10px 10px',
        maxHeight: '65px'
    }
};
// Customizable Area End
